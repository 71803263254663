import { BLOCKS, INLINES, Node } from "@contentful/rich-text-types"
import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { resolveIsEmail } from "../../contentful/options/rich-text"
import RichText from "../../resolvers/rich-text/rich-text.resolver"
import { AppFunctionComponent, ContentfulSection } from "../../types"
import { TextLink } from "../../ui/components/link/link.component"
import { SecondaryHeading } from "../common/heading.component"
import { FullSection } from "../common/sections/section.component"

const paragraphStyles = css`
  text-align: center;
  font-size: ${14 / 16}rem;
  line-height: ${18 / 14};
  margin: 0;
`

const EmailSection = styled(FullSection)`
  background-color: ${({ theme }) => theme.color.backgroundAttentionLight};
  margin-top: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-top: 60px;
  }
`

const SendCvHeading = styled(SecondaryHeading)`
  text-align: center;
  max-width: 280px;
  margin: 90px auto 30px auto;
`

const SendCvMail = styled(TextLink)`
  width: max-content;
  text-align: center;
  margin: 0 auto;
  display: block;
`

const SendCvParagraph = styled.p`
  ${paragraphStyles};
`

const CvClause = styled.div`
  margin-top: 90px;

  p {
    ${paragraphStyles};

    &:last-of-type {
      font-size: ${11 / 16}rem;
      line-height: ${14 / 11};
      text-align: center;
      margin: 5px auto 50px auto;
      max-width: 760px;
    }
  }
`

const CareerSendCv: AppFunctionComponent<{ content: ContentfulSection }> = ({
  content,
}) => {
  const { text, sectionElement } = content
  return (
    <EmailSection>
      {text && (
        <RichText
          content={text}
          options={{
            [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
              <SendCvHeading renderAs={"h2"}>{children}</SendCvHeading>
            ),
            [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => (
              <SendCvParagraph>{children}</SendCvParagraph>
            ),
            [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => (
              <SendCvMail to={resolveIsEmail(node.data.uri)}>
                {children}
              </SendCvMail>
            ),
          }}
        />
      )}
      <CvClause>
        {sectionElement &&
          sectionElement.map((element, index) => (
            <React.Fragment key={index}>
              {element.text && <RichText content={element.text} />}
            </React.Fragment>
          ))}
      </CvClause>
    </EmailSection>
  )
}

export default CareerSendCv
